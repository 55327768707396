<template>
  <footer class="base-footer">
    <a href="https://www.moex.com/" target="_blank" class="base-footer__logo">
      <img src="@/assets/images/logo.png" class="base-footer__logo-img" />
    </a>
    <a href="https://fs.moex.com/f/3499/agreement.pdf" class="base-footer__policy small-text">
      {{ i18n.policy }}
    </a>
  </footer>
</template>

<script>
export default {
  name: 'BaseFooter',
  computed: {
    i18n() {
      return this.$store.state.i18n.messages;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '@/assets/styles/vars/variables';
@require '@/assets/styles/mixins/mixins';
.base-footer {
  margin-top: 183px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 216px;
  padding-bottom: 27px

  +breakpoint(ms-and-down) {
    margin-top:139px;
    height: 242px;
    padding-bottom: 21px
  }

  &__logo {
    display block;
    max-width: 155px;
    opacity: 0.9;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }

    &-img {
      display block
      max-width 100%
    }
  }

  &__policy {
    text-align: center;
    text-decoration: underline;
    opacity: 0.3;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
