<template>
  <div ref="card" :class="getClasses" class="partner-card">
    <div v-if="item" class="partner-card__body" @click="openLink">
      <img class="partner-card__img" :src="item.img" />
      <div class="partner-card__btn">
        <a
          ref="link"
          :class="{ 'partner-card__link_hoverable': item.extra_text }"
          class="partner-card__link"
          :href="item.url"
          target="_blank"
        >
          {{ i18n.openBill }}
        </a>
        <span class="partner-card__extraText">{{ item.extra_text }}</span>
        <div>
          <base-icon class="partner-card__icon" name="link" />
        </div>
      </div>
      <img v-if="item.extra_img" class="partner-card__img_extra" :src="item.extra_img" />
    </div>
    <div v-else class="partner-card__body_nop">
      {{ i18n.newPartner }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  computed: {
    getClasses() {
      return [!this.item ? 'disabled' : ''];
    },
    i18n() {
      return this.$store.state.i18n.messages;
    },
  },
  mounted() {
    if (this.item?.color) this.$refs.card.style.setProperty('--extra-color', this.item.color);
  },
  methods: {
    openLink() {
      this.$refs.link.click();
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '@/assets/styles/mixins/mixins';
$theme={
  text:#d3d3d3,
  focus:#ce1126
}


.partner-card
  flexy(center,center)
  width 230px
  height 142px
  border: 1px solid #ebebeb;
  border-radius: 4px;
  overflow:hidden
  cursor pointer
  color:$theme.text
  font-family: EngraversGothic;
  font-size: 16px;
  font-weight: 700;
  transition color 0.5s
  background:white
  &:hover
    color:var(--extra-color, $theme.focus)
    .partner-card
      &__img
        &_extra
          -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0.7)), to(rgba(0,0,0,0)));
      &__extraText
        display:block
      &__link
        &_hoverable
          display:none
  &__body
    position relative
    flexy(space-between,flex-start,nowrap,column)
    size(100%,100%)
    padding: 24px 16px 22px;
    &_nop
      text-align:center
      font-size: 18px;
  &__img
    max-height 40px
    object-fit: contain
    &_extra
      position:absolute
      width 104px
      height 104px
      object-fit: contain
      right: -35px;
      top: 73px;
  &__btn
    width 100%
    flexy(space-between,flex-end)
    z-index 9999
    height 40%
  &__extraText
    display:none
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.025em;
  &__icon
    flexy(flex-end,flex-end)
    width 25px
    height 25px
    margin-left 30px
.disabled
  &.partner-card
    background-color: rgba(255,255,255,0.1);
    transition: opacity 0.3s;
    opacity: 0.6;
    cursor:auto
    color: #ffff;
</style>
