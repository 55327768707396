<template>
  <div id="app">
    <div class="page">
      <base-header fixed class="page__header">
        <base-btn
          :plain="isMobile"
          :color="isMobile ? 'primary' : 'secondary'"
          @click="scrollToSection('#openBill')"
        >
          {{ i18n.openBill }}
        </base-btn>
      </base-header>
      <main class="page__main">
        <component
          :is="components[item.component]"
          v-for="(item, i) in sections"
          :key="i"
          v-bind="item"
          @anchor-click="scrollToSection"
        />
      </main>
      <base-footer />
    </div>
  </div>
</template>
<script>
import breakpoints from './utils/breakpoints';
import ScrollbarWidth from '@/utils/scrollbar-width';
import SectionCta from './components/SectionCta.vue';
import SectionAdvantages from './components/SectionAdvantages.vue';
import SectionInvest from './components/SectionInvest.vue';
import SectionBuy from './components/SectionBuy.vue';
import SectionPartners from './components/SectionPartners.vue';
import SectionSubscription from './components/SectionSubscription.vue';
import SectionFaq from './components/SectionFaq.vue';
import BaseHeader from './components/BaseHeader.vue';
import BaseFooter from './components/BaseFooter.vue';
export default {
  name: 'App',
  components: { BaseHeader, BaseFooter },
  data() {
    return {
      loading: true,
      components: {
        cta: SectionCta,
        advantages: SectionAdvantages,
        invest: SectionInvest,
        buy: SectionBuy,
        partners: SectionPartners,
        subsription: SectionSubscription,
        faq: SectionFaq,
      },
      metatags: null,
    };
  },
  computed: {
    scrollbarWidth() {
      return this.$store.state.screen.scrollbar;
    },
    screenWidth() {
      return this.$store.state.screen.width;
    },
    isMobile() {
      return this.screenWidth <= breakpoints.sm;
    },
    sections() {
      return this.$store.state.data.sections;
    },
    i18n() {
      return this.$store.state.i18n.messages;
    },
    headerHeight() {
      return this.$store.state.headerHeight;
    },
  },
  metaInfo() {
    return this.$store.getters['data/meta'];
  },
  mounted() {
    this.setScreenSize();
    window.addEventListener('resize', this.setScreenSize);
    this.fetch();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setScreenSize);
  },
  methods: {
    setScreenSize() {
      const width = Math.min(screen.availWidth, window.innerWidth);
      const height = Math.min(screen.availHeight, window.innerHeight);
      const orientation = window.matchMedia('(orientation: portrait)').matches
        ? 'portrait'
        : 'landscape';
      const scrollbar = ScrollbarWidth();
      this.$store.commit('setScreen', { width, height, orientation, scrollbar });
    },
    fetch() {
      this.$store
        .dispatch('fetch')
        .then(() => (this.metatags = this.$store.state.data.meta))
        .catch(e => console.error(e))
        .finally(() => (this.loading = false));
    },
    scrollToSection(target) {
      const to = document.querySelector(target).offsetTop;
      window.scrollTo({ top: to - this.headerHeight, behavior: 'smooth' });
    },
  },
};
</script>

<style scoped lang="stylus">
@require '~@/assets/styles/vars/variables';
.page {
  &__main {
    margin-top: 120px;
  }
}
</style>
