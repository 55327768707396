<template>
  <div :class="getClasses" class="base-collapser">
    <div class="base-collapser__header" @click="toogle">
      <slot name="header">{{ title }}</slot>
      <base-icon
        :class="{ 'base-collapser__icon_rotate': isShow }"
        class="base-collapser__icon"
        name="angle-down"
      />
    </div>
    <transition name="slide-fade">
      <div v-if="isShow" class="base-collapser__content">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import showHideMixin from '@/mixins/showHideMixin.vue';
export default {
  name: 'BaseCollapser',
  mixins: [showHideMixin],
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    getClasses() {
      return [this.isShow ? 'showned' : ''];
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '@/assets/styles/vars/variables';
@require '@/assets/styles/mixins/mixins';
$theme-light = {
  border: $border-color.base,
  header: $text-color.base,
  focused: $primary-color,
  content: $text-color.light,
}

.base-collapser
  border: 1px solid $theme-light.border;
  border-radius: 4px;
  max-width: 776px;
  transition all 1s;
  font-family: $font-base;
  height: auto;
  //max-height: 112.8px;
  overflow: hidden;
  &__header
    position: relative;
    cursor pointer
    flexy(space-between,flex-start)
    font-size: 24px;
    font-weight: 400;
    color:$theme-light.header;
    padding: 16px 72px 20px 24px;
    +breakpoint(ms-and-down) {
      font-size $font-size-base;
      padding: 16px 20% 20px 24px;
    }
    &:hover
      .base-collapser
        &__icon
          color:$theme-light.focused
  &__content
    padding: 0 24px 20px 24px;
    color: $theme-light.content
    font-size: 21px;
  &__icon
    font-size 24px
    position: absolute;
    top: 20px;
    right: 24px;
    color: $theme-light.border
    transition:all 0.5s
    &_rotate
      transform:rotate(180deg)
.showned
  max-height: 1000px;
</style>
