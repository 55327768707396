import Vue from 'vue';
import Vuex from 'vuex';
import i18n from './localeModule';
import data from './dataModule';

Vue.use(Vuex);

const state = () => ({
  screen: {
    width: 0,
    height: 0,
  },
  headerHeight: 79,
});

const mutations = {
  setScreen(state, value) {
    state.screen = value;
  },
  setHeaderHeight(state, value) {
    state.headerHeight = value;
  },
};

const actions = {
  async fetch({ dispatch }) {
    dispatch('data/fetch');
    dispatch('i18n/fetch');
  },
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {
    i18n,
    data,
  },
});
