<template>
  <section class="section section-partners" :class="classes">
    <div class="section-partners__content container">
      <div class="section-partners__heading">
        <h2 class="section-partners__title large-title">
          {{ title }}
        </h2>
        <p v-if="subtitle" class="section-partners__subtitle medium-text">
          {{ subtitle }}
        </p>
      </div>
      <div class="section-partners__cards">
        <div v-for="(item, idx) in list" :key="idx" class="section-partners__card-container">
          <partner-card :item="item" class="section-partners__card" />
        </div>
        <div class="section-partners__card-container">
          <partner-card class="section-partners__card" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PartnerCard from './cards/PartnerCard.vue';
export default {
  name: 'SectionParners',
  components: { PartnerCard },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    theme: {
      type: String,
      default: 'primary',
      validator: value => ['primary', 'dark'].includes(value),
    },
  },
  computed: {
    classes() {
      const prefix = 'section-partners';
      return {
        [`${prefix}--${this.theme}`]: !!this.theme,
      };
    },
  },
};
</script>

<style scoped lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.section-partners {
  margin-top: 142px;
  +breakpoint(lg-and-down) {
    margin-top 108px;
  }
  +breakpoint(ms-and-down) {
    margin-top 56px;
  }

  &__content {
    position: relative;
    padding: 48px 96px 96px 136px;
    overflow: hidden
    max-width: 1048px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    color: white;
    margin-left: 13.3%;
    +breakpoint(lg-and-down) {
      margin 0 48px
    }
    +breakpoint(ms-and-down) {
      max-width 100%
      margin 0
      padding: 48px 0 70px 0;
    }

    ^[0]--primary & {
      background: $gradient.primary;
    }
    ^[0]--dark & {
      background: $gradient.dark;
    }
  }

  &__title {
    text-transform uppercase;
    +breakpoint(ms-and-down) {
      font-size $font-size-big
      margin-left:24px;
    }
  }

  &__subtitle {
    line-height: 160%;
    letter-spacing: 0.01em;
    margin-top: 43px;
    +breakpoint(ms-and-down) {
      display none
    }
  }

  &__cards {
    margin-top: 56px;
    display: flex;
    flex-wrap: wrap;
    -webkit-overflow-scrolling: touch;
    -webkit-scroll-snap-type: x mandatory;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory
    +breakpoint(ms-and-down) {
      flex-wrap:nowrap;
      overflow-x: scroll;
      margin-top: 24px
    }

    &::-webkit-scrollbar {
      display: none
    }
  }

  &__card {
    scroll-snap-align: start;
    scroll-margin: 24px;

    &-container {
      padding-right: 40px;
      padding-bottom: 40px;

      &:first-child {
        +breakpoint(ms-and-down) {
          margin-left 24px
        }
      }
    }
  }
}
</style>
