import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Это поле обязательное',
});

extend('email', {
  ...email,
  message: 'Это не похоже на email',
});

export { ValidationProvider, ValidationObserver };
