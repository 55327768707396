<template>
  <div class="base-checkbox" :class="getClasses">
    <div class="base-checkbox__field">
      <input
        :id="internalId"
        type="checkbox"
        class="base-checkbox__input"
        v-bind="$attrs"
        :checked="checked"
        :disabled="disabled"
        @change="onChange"
      />
      <div class="base-checkbox__label">
        <label :for="internalId" class="base-checkbox__box">
          <base-icon name="check" class="base-checkbox__check-icon" />
        </label>
        <label v-if="label || $slots.default" :for="internalId" class="base-checkbox__label-text">
          <slot>{{ label }}</slot>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      internalId: this.id || `uid_${(~~(Math.random() * 1e8)).toString(16)}`,
    };
  },

  computed: {
    getClasses() {
      return [this.checked ? 'checked' : ''];
    },
  },

  methods: {
    onChange(event) {
      this.$emit('change', event.target.checked);
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.base-checkbox {
  width: 100%;

  &__input {
    display: none;
  }
  &__field {
    relative();
  }
  &__label {
    flexy(flex-start, flex-start);
  }
  &__box {
    flexy(center,center)
    color white
    border: 1px solid $input-color.border;
    border-radius: 4px;
    cursor: pointer;
  }
  &__check-icon {
    padding 1px
    width 24px
    height 24px
  }
  &__label-text {
    font-size: 18px;
    margin-left: 8px;
    color: $input-color.text;
    cursor: pointer;
  }
}

.checked{
  .base-checkbox{
    &__box{
      background: $input-color.check;
    }
  }
}
</style>
