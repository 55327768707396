<template>
  <section class="section section-invest">
    <div class="section-invest__heading">
      <h2 class="section-invest__title bigger-title">
        {{ title }}
      </h2>
      <p v-if="subtitle" class="section-invest__subtitle medium-text">
        {{ subtitle }}
      </p>
    </div>
    <div class="section-invest__content container">
      <ul
        v-for="(list, index) in lists"
        :key="index"
        :class="index % 2 === 0 ? 'left' : 'right'"
        class="section-invest__list"
      >
        <li v-for="(item, idx) in list" :key="idx" class="section-invest__item medium-text">
          <base-icon
            :name="item.icon"
            :class="index % 2 === 0 ? 'primary-color' : 'white-color'"
            class="section-invest__item-icon"
          />
          <h3 class="section-invest__item-title big-title">
            {{ item.title }}
          </h3>
          <p class="section-invest__item-text standart-text">
            {{ item.text }}
          </p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionInvest',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    lists: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.section-invest {
  margin-top: 106px;

  +breakpoint(sm-and-down) {
    margin-top:24px;
  }
  +breakpoint(ms-and-down) {
    margin-top: 0;
  }

  &__heading {
    margin-left: calc(13.3% + 113px)
    +breakpoint(xl-and-down) {
      margin-left:13.3%
    }
    +breakpoint(sm-and-down) {
      margin-left: 48px
    }
    +breakpoint(ms-and-down) {
      padding-right: 24px;
      margin-left: 24px
    }
  }

  &__title {
    +breakpoint(ms-and-down) {
      font-size: $font-size-big;
    }
  }
  &__subtitle {
    margin-top: 44px;
    max-width: 650px;
    +breakpoint(ms-and-down) {
      font-size: $font-size-base;
      margin-top 30px;
    }
  }
  &__content {
    margin-top: 130px;
    position: relative
    +breakpoint(sm-and-down) {
      margin-top 0;
    }
    +breakpoint(ms-and-down) {
      margin 0;
    }

    &::before {
      content: "";
      display: block;
      size(36px);
      background-color: $primary-color;
      absolute(0, 0px);
      +breakpoint(ms-and-down) {
        display none
      }
    }
  }

  &__list {
    &.left {
      absolute(-56px);
      max-width: 592px;
      height: 558px;
      background: rgba(255,255,255,0.95);
      box-shadow: 0px 0px 20px rgba($shadow-color,0.1);
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
      border-radius: 8px;
      padding: 55px 87px 61px 113px;
      z-index: 2
      +breakpoint(xl-and-down) {
        padding-left:136px
      }
      +breakpoint(ml-and-down) {
        position: relative;
        transform: translateY(56px)
      }
      +breakpoint(ms-and-down) {
        height:auto;
        padding: 56px 43px 48px 16px;
        transform: translateY(210px);
        max-width: 96%;
        margin-top: -88px;
        margin-left: 2%;
        margin-right: 2%
      }
    }
    &.right {
      background: url(@/assets/images/invest-bg-desktop.png) center right no-repeat;
      margin-left: auto;
      width: 70%;
      min-height: 663px;
      position: relative;
      background-size: cover;
      padding: 119px 0 0 28%;
      color: white;
      z-index: 1

      +breakpoint(ml-and-down) {
        min-height:448px
      }
      +breakpoint(sm-and-down) {
        width:85%
      }
      +breakpoint(ms-and-down) {
        width: 100%;
        min-height: 0;
        background-image: url(@/assets/images/invest-bg-mobile.png);
        background-position: center;
        padding: 248px 24px 80px 24px
      }
    }
  }

  &__item {
    position: relative;

    & + & {
      +breakpoint(ms-and-down) {
        margin-top: 77px;
      }
    }

    &-icon {
      absolute(8px, -40px);

      +breakpoint(ms-and-down) {
        top: -32px;
        left: 0;
      }
    }

    &-title {
      text-transform uppercase;
      +breakpoint(ms-and-down) {
        font-size: $font-size-medium;
      }
    }

    &-text {
      margin-top: 10px;
      max-width: 323px;
      +breakpoint(ms-and-down) {
        font-size: $font-size-small;
        max-width:100%;
      }
    }
  }
}
</style>
