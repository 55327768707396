<template>
  <section class="section section-subsription">
    <div class="section-subsription__heading">
      <h2 class="section-subsription__title big-title">
        {{ title }}
      </h2>
      <p v-if="subtitle" class="section-subsription__subtitle medium-text">
        {{ subtitle }}
      </p>
    </div>
    <section-subscription :fields="fields" class="section-subsription__form" />
    <base-socials :list="socials" class="section-subsription__socials" />
  </section>
</template>

<script>
import SectionSubscription from '@/components/form/SubscriptionForm.vue';
import BaseSocials from './BaseSocials.vue';
export default {
  name: 'SectionSubsription',
  components: { SectionSubscription, BaseSocials },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    fields: {
      type: Object,
      default: null,
    },
    socials: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.section-subsription {
  max-width: 912px;
  padding: 98px 96px 111px 126px;
  background: rgba(255,255,255,0.95);
  box-shadow: 0px 0px 20px rgba($shadow-color, 0.1);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-radius: 8px;
  margin-top: -75px;
  margin-right: 13.3%
  margin-left auto
  +breakpoint(xl-and-down) {
    margin-right:48px
    margin-left auto
  }
  +breakpoint(sm-and-down) {
     margin-top: -56px;
    margin-right: 50px
    margin-left: 50px
  }
  +breakpoint(ms-and-down) {
    margin:0 auto;
    max-width: 96%;
    padding: 24px 16px 48px 16px
  }

  &__title {
    text-transform uppercase;
    +breakpoint(ms-and-down) {
      font-size $font-size-medium
    }
  }

  &__subtitle {
    margin-top 38px;
    +breakpoint(ms-and-down) {
      margin-top 12px;
      font-size $font-size-base
    }
  }

  &__form {
    margin-top 26px;
  }

  &__socials {
    margin-top: 71px
    +breakpoint(ms-and-down) {
      margin-top 136px;
      justify-content: center;
    }
  }
}
</style>
