<template>
  <section class="section section-buy">
    <div class="section-buy__heading container">
      <h2 class="section-buy__title bigger-title">
        {{ title }}
      </h2>
      <p v-if="subtitle" class="section-buy__subtitle medium-text">
        {{ subtitle }}
      </p>
    </div>
    <div class="section-buy__content">
      <ul class="section-buy__list">
        <li v-for="(item, idx) in list" :key="idx" class="section-buy__item">
          <p class="section-buy__item-title big-title">
            {{ item.title }}
          </p>
          <p class="section-buy__item-text standart-text">
            {{ item.text }}
          </p>
        </li>
      </ul>
      <p v-if="tip" class="section-buy__tip big-title">
        {{ tip }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionBuy',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    tip: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.section-buy {
  margin-top: 107px;
  +breakpoint(sm-and-down) {
    margin-top:80px;
  }
  +breakpoint(ms-and-down) {
    margin-top: 56px;
  }

  &__heading {
    max-width 1100px
    +breakpoint(xl-and-down) {
      margin-left:13.3%
    }
    +breakpoint(sm-and-down) {
      margin-left: 48px
    }
    +breakpoint(ms-and-down) {
      margin-left: 24px
      margin-right 24px
    }
  }

  &__title {
    letter-spacing: 0.015em
    +breakpoint(ms-and-down) {
      font-size $font-size-big
    }
  }

  &__content {
    margin-top: 100px;
    margin-left: 13.3%
    +breakpoint(sm-and-down) {
      margin-left: 48px
    }
    +breakpoint(ms-and-down) {
      margin: 0 24px;
    }
  }

  &__list {
    display flex
    +breakpoint(ms-and-down) {
      flex-direction: column
    }
  }

  &__item {
    margin-top 0
    flex-basis 25%;
    padding-right: 40px
    +breakpoint(ms-and-down) {
      padding-right:24px;
      margin-top: 30px;
      min-width: 100%
    }

    &-text {
      line-height 1.8
      +breakpoint(ms-and-down) {
        margin-top: 0;
        font-size $font-size-small
      }
    }
  }

  &__tip {
    margin-top: 90px;
    line-height: 1;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    max-width: 680px;

    +breakpoint(ms-and-down) {
      margin-top:56px;
      font-size $font-size-medium
    }

    b {
      color: $primary-color;
    }
  }
}
</style>
