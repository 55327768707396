<template>
  <ValidationObserver ref="form" class="subscription-form" tag="form" @submit.prevent="onSubmit">
    <div class="subscription-form__input">
      <components
        v-bind="fields.email"
        :is="components[fields.email.type].tag"
        v-model="form.email"
        :rules="components[fields.email.type].rules"
      ></components>
      <base-btn v-if="!isMobile" type="submit">
        {{ i18n.subscribe }}
      </base-btn>
    </div>
    <components
      v-bind="fields.personaldata"
      :is="components[fields.personaldata.type].tag"
      v-model="form.personaldata"
    ></components>
    <base-btn v-if="isMobile" class="subscription-form__btn" type="submit">
      {{ i18n.subscribe }}
    </base-btn>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from '@/plugins/vee-validate.js';
import breakpoints from '@/utils/breakpoints';
export default {
  name: 'SubscriptionForm',
  components: { ValidationObserver },
  props: {
    fields: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        email: '',
        personaldata: false,
      },
      components: {
        email: {
          tag: 'base-input',
          rules: 'required|email',
        },
        checkbox: {
          tag: 'base-checkbox',
        },
      },
    };
  },

  computed: {
    screenWidth() {
      return this.$store.state.screen.width;
    },
    isMobile() {
      return this.screenWidth <= breakpoints.sm;
    },
    i18n() {
      return this.$store.state.i18n.messages;
    },
  },

  methods: {
    onSubmit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }

        console.log(this.form);
        // Wait until the models are updated in the UI
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '@/assets/styles/mixins/mixins';
.subscription-form
  &__input
    flexy(space-between,flex-start)
    margin-bottom:29px
  &__btn
    margin-top:29px
</style>
