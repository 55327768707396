<template>
  <section class="section section-cta">
    <div class="section-cta__heading">
      <h1 class="section-cta__title large-title">
        {{ title }}
      </h1>
      <p v-if="subtitle" class="section-cta__subtitle big-text">
        {{ subtitle }}
      </p>
    </div>
    <div v-if="list.length" class="section-cta__content container">
      <div class="section-cta__info">
        <ul class="section-cta__list medium-text">
          <li v-for="(item, idx) in list" :key="idx" class="section-cta__list-item">
            <base-icon :name="item.icon" class="section-cta__list-icon primary-color" />
            {{ item.text }}
          </li>
        </ul>
        <base-btn
          v-if="btn"
          :color="btn.color || 'primary'"
          :href="btn.type === 'link' ? btn.href : null"
          :target="btn.type === 'link' ? btn.target : null"
          class="section-cta__btn"
          @click="btn.type === 'anchor' ? $emit('anchor-click', btn.href) : null"
        >
          {{ btn.text || i18n.openBill }}
        </base-btn>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionCta',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    btn: {
      type: Object,
      default: null,
    },
  },
  computed: {
    i18n() {
      return this.$store.state.i18n.messages;
    },
  },
};
</script>

<style scoped lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.section-cta {
  &__heading {
    margin-left: 23.3%;
    max-width: 650px;

    +breakpoint(xl-and-down) {
      margin-left: 13.3%
    }
    +breakpoint(ms-and-down) {
      margin-left: 24px
    }
  }

  &__title {
    +breakpoint(ms-and-down) {
      font-size:32px;
      padding-right: 24px
    }
  }

  &__subtitle {
    letter-spacing: 0.03em
    +breakpoint(ms-and-down) {
      font-size:26px;
      padding-right: 24px
    }
  }

  &__content {
    position: relative;
    margin-top: 80px;

    &::before {
      content: "";
      display: block;
      size(70%, 560px);
      background: url(@/assets/images/cta-bg-desktop.png) center right no-repeat;
      background-size: cover;
      margin-left auto;

      +breakpoint(ms-and-down) {
        background-position:center;
        background-image: url(@/assets/images/cta-bg-mobile.png);
        size(100%, 448px);
      }
    }

    &::after {
      content: "";
      display: block;
      size(48px);
      background-color: $primary-color;
      position: absolute;
      bottom: 0

      +breakpoint(ms-and-down) {
        display none
      }
    }

    +breakpoint(xl-and-down) {
      margin-left: 48px
    }
    +breakpoint(ms-and-down) {
      margin: 45px 0 0 0;
    }
  }

  &__info {
    position: absolute;
    max-width: 650px;
    min-height: 406px;
    background: rgba(255,255,255,0.95);
    box-shadow: 0px 0px 20px rgba($shadow-color, 0.1);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    top: 74px;
    border-radius: 8px;
    padding: 56px 48px 56px 74px;
    z-index: 2

    +breakpoint(ms-and-down) {
      max-width: 96%;
      margin: -56px auto 0;
      font-size: $font-size-base;
      position: static;
      padding: 56px 16px
    }
  }

  &__list {
    &-item {
      position: relative;
      padding-left: 62px;

      +breakpoint(ms-and-down) {
        padding-left: 0;
      }
    }
    &-icon {
      absolute(7px, 0px);

      +breakpoint(ms-and-down) {
        position: relative;
        top: auto;
        display block;
        margin-bottom: 0.25em;
      }
    }
  }

  &__btn {
    margin-top: 30px;
  }
}
</style>
