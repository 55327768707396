const state = () => ({
  locale: 'ru',
  messages: {},
});

const mutations = {
  setLocale(state, locale) {
    state.locale = locale;
  },
  setMessages(state, messages) {
    state.messages = messages;
  },
};

const actions = {
  async fetch({ commit }, locale = 'ru') {
    const messages = await import(`../data/${locale}.json`);
    commit('setMessages', messages.default);
  },
};

export default {
  state,
  mutations,
  actions,
  namespaced: true,
};
