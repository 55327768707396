<template>
  <component
    :is="getTag"
    :href="href"
    :type="type"
    :class="getClasses"
    class="base-btn"
    @click="click"
  >
    <slot>{{ title }}</slot>
  </component>
</template>

<script>
export default {
  name: 'BaseBtn',
  props: {
    title: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      validator: value => ['md'].includes(value),
      default: 'md',
    },
    color: {
      type: String,
      validator: value => ['primary', 'secondary'].includes(value),
      default: 'primary',
    },
    plain: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
  },
  computed: {
    getClasses() {
      return [this.plain ? 'plain' : '', `size_${this.size}`, `color_${this.color}`];
    },
    getTag() {
      return this.href ? 'a' : 'button';
    },
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '@/assets/styles/vars/variables';
@require '@/assets/styles/mixins/mixins';
$themes={
  primary: {
    base: $primary-color,
    focused: $primary-color-darker,
    text: white
  },
  secondary: {
    base: $secondary-color,
    focused: $secondary-color-darker,
    text: white
  }
}

.size
  &_md
    width 232px

for $key in $themes
  .color
    &_{$key}
      background:$themes[$key].base
      color:$themes[$key].text
      &:hover
        background:$themes[$key].focused
  .plain
    &.color
      &_{$key}
        background transparent
        color:$themes[$key].base

.base-btn
  flexy(center,center)
  font-family:$font-accent
  height 48px
  border none
  cursor pointer
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  transition: background-color 0.3s;
  white-space: nowrap;
.plain
  width:auto
  &:hover
    text-decoration underline
</style>
