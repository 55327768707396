<template>
  <validation-provider v-slot="{ classes, errors }" :rules="rules" class="base-input">
    <input
      v-model="inputVal"
      :class="classes"
      class="base-input__input"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :type="type"
    />
    <div class="base-input__notify">
      <span v-if="classes.dirty && errors[0]">{{ error ? error : errors[0] }}</span>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from '@/plugins/vee-validate.js';
export default {
  name: 'BaseInput',
  components: { ValidationProvider },
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
      validator: value => ['text', 'email', 'tel', 'url'].includes(value),
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    getClasses() {
      const prefix = 'base-input';
      return '';
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '@/assets/styles/vars/variables';
@require '@/assets/styles/mixins/mixins';

.base-input
  flexy(flex-start,flex-start,nowrap,column)
  position:relative
  width:368px
  +breakpoint(ms-and-down) {
    width:100%
  }
  &__input
    padding: 6px 16px;
    height 48px
    font-size: 21px;
    background-color: $input-color.bg;
    border: 1px solid $input-color.border;
    border-radius: 4px;
    transition all 0.5s
    width 100%
    +breakpoint(ms-and-down) {
      height 38px
    }
    &:focus
      box-shadow: 0 0 0 3px $input-color.shadow;
      border-color black
  &__notify
    color: $input-color.error
    margin-top 8px
    font-size: 18px;
.dirty.invalid
  border-color $input-color.error
</style>
