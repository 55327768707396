const state = () => ({
  meta: {},
  sections: [],
});

const mutations = {
  setSections(state, sections) {
    state.sections = sections;
  },
  setMeta(state, meta) {
    state.meta = meta;
  },
};

const getters = {
  meta: state => {
    const metaInfo = { title: state.meta.title, meta: [] };
    if (state.meta.description)
      metaInfo.meta.push({ name: 'description', content: state.meta.description });
    let props = Object.keys(state.meta).filter(item => item !== 'title' && item !== 'description');
    for (let prop of props) metaInfo.meta.push({ property: prop, content: state.meta[prop] });
    return metaInfo;
  },
};

const actions = {
  async fetch({ commit }) {
    const data = await import('../data/demodata.json');
    commit('setMeta', data.default.meta);
    commit('setSections', data.default.sections);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true,
};
