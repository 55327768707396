import Vue from 'vue';
import App from './App.vue';
import store from './store';
import VueMeta from 'vue-meta';

Vue.use(VueMeta);

// global styles
import '@/assets/styles/index.styl';

//global components
import '@/components/globalComponents';

Vue.config.productionTip = false;

new Vue({
  store,
  render: h => h(App),
}).$mount('#app');
