<template>
  <section class="section section-advantages">
    <div class="section-advantages__heading">
      <h2 class="section-advantages__title bigger-title">
        {{ title }}
      </h2>
      <p v-if="subtitle" class="section-advantages__subtitle medium-text">
        {{ subtitle }}
      </p>
    </div>
    <div class="section-advantages__content container">
      <div class="section-advantages__info">
        <ul class="section-advantages__list standart-text">
          <li v-for="(item, idx) in list" :key="idx" class="section-advantages__list-item">
            {{ item.text }}
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionAdvantages',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.section-advantages {
  margin-top: 216px;

  +breakpoint(ml-and-down) {
    margin-top: 106px;
  }
  +breakpoint(sm-and-down) {
    margin-top:80px;
  }
  +breakpoint(ms-and-down) {
    margin-top:80px;
  }

  &__heading {
    margin-left: 23.3%;
    max-width: 776px;

    +breakpoint(xl-and-down) {
      margin-left: 13.3%;
    }
    +breakpoint(sm-and-down) {
      margin-left: 48px;
    }
    +breakpoint(ms-and-down) {
      margin-left: 24px
      padding-right: 48px;
    }
  }

  &__title {
    +breakpoint(ms-and-down) {
      font-size: $font-size-big;
    }
  }

  &__subtitle {
    margin-top: 45px;
    +breakpoint(ms-and-down) {
      font-size: $font-size-base;
    }
  }

  &__content {
    position: relative;
    margin-top: 126px;

    +breakpoint(sm-and-down) {
      margin-top: 50px;
    }
    +breakpoint(ms-and-down) {
      margin:24px 0 0 0
    }

    &::before {
      content: '';
      display block;
      margin-right: auto;
      size(56%, 608px);
      background: url('@/assets/images/adv-bg-desktop.png') center left no-repeat;
      background-size: cover;

      +breakpoint(sm-and-down) {
        size(100%, 448px);
      }
      +breakpoint(ms-and-down) {
        background-position:center;
        background-image: url('@/assets/images/adv-bg-desktop.png');
      }
    }

    &::after {
      content: "";
      display: block;
      size(48px);
      background-color: $primary-color;
      absolute(0, 0, 0px, 0px);

      +breakpoint(sm-and-down) {
        display none;
      }
    }
  }

  &__info {
    absolute(-56px, 0, 0, 0px);
    max-width: 912px;
    min-height: 496px;
    background: rgba(255,255,255,0.95);
    box-shadow: 0px 0px 20px rgba($shadow-color,0.1);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    border-radius: 8px;
    padding: 48px 45px 13px 67px

    +breakpoint(sm-and-down) {
      width:96%;
      position: static;
      transform: translateY(-56px);
      margin: 0 auto
    }

    +breakpoint(ms-and-down) {
      padding:24px 16px 25px 0
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    &-item {
      flex-basis: 50%;
      margin-bottom: 77px;
      padding-left: 45px;
      position: relative
      margin-top 0

      +breakpoint(ms-and-down) {
        flex-basis: 100%;
        margin-bottom: 40px;
        font-size: 19px
      }

      &::before {
        display block;
        content: "";
        size(8px);
        background: $primary-color;
        absolute(12px, 20px);
      }
    }
  }
}
</style>
