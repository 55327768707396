<template>
  <div class="base-socials">
    <a
      v-for="item in list"
      :key="item.code"
      :href="item.url"
      :title="item.title"
      :class="`base-socials__item_${item.code}`"
      class="base-socials__item"
    >
      <base-icon class="base-socials__icon" :name="item.code" />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '@/assets/styles/mixins/mixins';
$theme={
  background:#51626f,
  icon:white,
  focus:blue
}
$socials={
  tg:#0088cc,
  inst:#833AB4,
  vk:#4C75A3,
  fb:#3b5998
}
for $key in $socials
  .base-socials
    &__item_{$key}
      &:hover
        background:$socials[$key]

.base-socials {
  flexy(flex-start,center)

  &__item {
    width: 32px;
    height: 32px;
    flexy(center,center)
    background:$theme.background
    color:$theme.icon
    padding 5px;
    border-radius: 4px;
    transition all 0.5s

    & + & {
      margin-left 32px
    }
  }
  &__icon {
    width: 32px;
    height: 32px;
  }
}
</style>
