<script>
export default {
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    toogle() {
      this.isShow = !this.isShow;
      this.isShow ? this.$emit('show') : this.$emit('hide');
    },
    show() {
      this.isShow = true;
      this.$emit('show');
    },
    hide() {
      this.isShow = false;
      this.$emit('hide');
    },
  },
};
</script>
