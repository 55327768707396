<template>
  <section class="section section-faq">
    <div class="section-faq__heading">
      <h2 class="section-faq__title bigger-title">
        {{ title }}
      </h2>
      <p v-if="subtitle" class="section-faq__subtitle medium-text">
        {{ subtitle }}
      </p>
    </div>
    <div class="section-faq__content">
      <base-collapser
        v-for="(item, idx) in list"
        :key="idx"
        :title="item.title"
        class="section-faq__item"
      >
        {{ item.text }}
      </base-collapser>
    </div>
  </section>
</template>

<script>
import BaseCollapser from './BaseCollapser.vue';
export default {
  name: 'SectionFaq',
  components: { BaseCollapser },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="stylus">
@require '~@/assets/styles/vars/variables';
.section-faq {
  margin-top: 104px;
  margin-left: 26.6%;
  +breakpoint(xl-and-down) {
    margin-left:13.3%
  }
  +breakpoint(sm-and-down) {
    margin-top: 50px;
    margin-left: 48px
  }
  +breakpoint(ms-and-down) {
    margin: 24px auto 0;
    max-width: 96%;
  }

  &__heading {
    max-width 1100px
    +breakpoint(ms-and-down) {
      margin: 0 -2%;
      padding: 0 24px;
    }
  }

  &__title {
    letter-spacing: 0.015em
    max-width: 500px;
    +breakpoint(ms-and-down) {
      font-size $font-size-big
    }
  }

  &__content {
    margin-top: 54px;
    //margin-left: 13.3%
    +breakpoint(sm-and-down) {
      //margin-left: 48px
    }
    +breakpoint(ms-and-down) {
      //margin:32px auto 0 auto;
      margin-top 32px
      max-width: 100%
    }
  }

  &__item {
    max-width: 776px;
    margin-top: 16px;
  }
}
</style>
