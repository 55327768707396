import Vue from 'vue';
import BaseIcon from './BaseIcon.vue';
import BaseBtn from './BaseBtn.vue';
import BaseCollapser from './BaseCollapser.vue';
import BaseInput from './BaseInput.vue';
import BaseCheckbox from './BaseCheckbox.vue';
import BaseSocials from './BaseSocials.vue';

Vue.component('BaseIcon', BaseIcon);
Vue.component('BaseBtn', BaseBtn);
Vue.component('BaseCollapser', BaseCollapser);
Vue.component('BaseInput', BaseInput);
Vue.component('BaseCheckbox', BaseCheckbox);
Vue.component('BaseSocials', BaseSocials);
// click-outside
import ClickOutside from 'vue-click-outside';
Vue.directive('click-outside', ClickOutside);
