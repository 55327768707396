<template>
  <header class="base-header" :class="classes">
    <div class="base-header__left">
      <a href="https://www.moex.com/" target="_blank" class="base-header__logo">
        <img src="@/assets/images/logo.png" class="base-header__logo-img" />
      </a>
    </div>
    <div v-if="$slots.default" class="base-header__right">
      <slot />
    </div>
  </header>
</template>

<script>
export default {
  name: 'BaseHeader',
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      const prefix = 'base-header';
      return {
        [`${prefix}--fixed`]: this.fixed,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '@/assets/styles/vars/variables';
@require '@/assets/styles/mixins/mixins';
.base-header {
  padding: 15px 48px;
  width: 100%;
  position: relative;
  background-color: rgba(255,255,255,0.9);
  border-bottom: 1px solid rgba(217,217,217,0.2);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  z-index: $header-z;
  flexy(space-between, center);

  &--fixed {
    fixed(0px, 0px);
  }

  +breakpoint(ms-and-down) {
    padding: 15px 24px;
  }

  &__logo {
    display block
    max-width: 155px;

    &-img {
      display block
      max-width: 100%;
    }
  }
}
</style>
